<template>
    <div>
        <div class="tw-flex tw-justify-between">
            <span class="tw-font-semibold tw-text-sm">Filters</span>
            <div class="tw-flex tw-gap-3">
                <button @click="resetFilters" class="tw-border tw-border-solid tw-border-blue-600 tw-cursor-pointer tw-text-sm tw-font-medium tw-px-2 tw-rounded-lg tw-bg-white tw-text-blue-600 tw-h-8 tw-w-14 tw-flex tw-items-center">
                    Reset
                </button>
                <button @click="applyFilters(filters)" class="tw-border tw-border-solid tw-border-blue-600 tw-cursor-pointer tw-px-2 tw-text-sm tw-font-medium tw-rounded-lg tw-bg-brand hover:tw-bg-brand-hover tw-text-white tw-h-8 tw-w-14 tw-flex tw-items-center">
                    Apply
                </button>
            </div>
        </div>
        <div class="tw-mt-4" v-for="filter in filters" :key="filter.title">
            <AccordionCard :isFilter="true" :fetchSelectedFilter="fetchFilterData" :filter="filter" :customTitle="customTitles">
                <template v-slot:title>
                    <div>
                        <span class="tw-font-medium tw-text-xs">
                            <img :src="filter.svg" class="tw-mt-3" />
                            <span class="tw-mx-2 tw-mb-4 tw-text-blue-600" v-if="filter.name != 'matchingIdentifiers'">{{ filter.title }} ({{ filter.data.filter((res) => res.checked).length }})</span>
                            <span class="tw-mx-2 tw-mb-4 tw-text-blue-600" v-if="filter.name == 'matchingIdentifiers'">{{ filter.title }} </span>
                        </span>
                    </div>
                </template>
                <template v-slot:titleCheckbox>
                    <div class="tw-ml-4 tw-flex tw-justify-center tw-items-end" v-if="showSelectAll(filter.name)">
                        <input class="tw-mt-1" type="checkbox" id="checkboxInput" v-model="filter.selectAll" @change="handleSelectAll(filter)" />
                        <label class="tw-mx-1">Select all</label>
                    </div>
                </template>
                <template v-slot:content>
                    <div v-if="!customTitles.includes(filter.name) || filter.name == 'internetQueries'">
                        <div class="form-control">
                            <Input class="tw-w-full tw-mt-1" v-model="filter.searchedTerm" @input="handleSearch(filter, caseId)" icon="search" placeholder="Search" />
                        </div>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-py-2 tw-pl-4">
                            <div c v-for="(res, cIdx) in filter.data" :key="res.name">
                                <input type="checkbox" :id="res.key" v-model="res.checked" class="" @change="selectInput(filter)" />
                                <label v-if="res.count" class="filter-item-label tw-font-normal tw-text-xs tw-mx-2">{{ res.key }} ({{ res.count }})</label>
                                <label v-else class="filter-item-label tw-font-normal tw-text-xs tw-mx-2">{{ res.key }}</label>
                            </div>
                        </div>
                        <loader :loading="!filter.dataFetched" class="tw-pt-2" />
                        <span v-if="filter.name != 'internetQueries' && filter.data.length" @click="(filter.page += 1), handleMoreData(filter)" class="tw-cursor-pointer tw-pl-3 tw-text-sm tw-text-blue-600">Load More</span>
                        <!-- <span v-if="filterData.seeMorePeople" @click="$emit('handleSeeMore', false, 'seeMorePeople')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
                    </div>
                    <div v-if="filter.name == 'topicClassification'">
                        <div class="form-control">
                            <Input class="tw-w-full tw-mt-1" v-model="filter.searchedTerm" @input="handleTopicSearch(filter)" icon="search" placeholder="Search" />
                        </div>

                        <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-py-2 tw-pl-2">
                            <TreeNode :treeData="filter.data" @toggleTopics="toggleTopics($event, filter)" />
                        </div>
                    </div>

                    <div v-if="staticFilters.includes(filter.name)">
                        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-py-1 tw-pl-1">
                            <div v-for="res in filter.data" :key="res.name" class="tw-flex">
                                <input v-if="filter.name != 'sources'" type="radio" :name="filter.name" :checked="res.checked" @change="handleRadioSelection(res.name, filter.data)" />
                                <input v-else type="checkbox" :id="res.key" v-model="res.checked" class="" @change="selectInput(filter)" />
                                <label class="filter-item-label tw-font-normal tw-text-xs tw-mx-2">{{ res.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="filter.name == 'matchingIdentifiers'">
                        <div class="tw-flex tw-gap-2 tw-justify-between">
                            <div class="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm tw-w-1/2">
                                <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                                    <span class="tw-text-gray-500 tw-text-sm">Min</span>
                                </div>
                                <input v-model="minValue" @input="setRange(filter)" type="number" name="price" id="price" class="tw-focus-bg-sky-500 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300" />
                            </div>

                            <div class="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm tw-w-1/2">
                                <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                                    <span class="tw-text-gray-500 tw-text-sm">Max</span>
                                </div>
                                <input v-model="maxValue" @input="setRange(filter)" type="number" name="price" id="price" class="tw-focus-bg-sky-500 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-11 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300" />
                            </div>
                        </div>
                        <span v-if="sliderError" class="tw-mt-2 tw-text-red-500 tw-text-sm">{{ sliderError }}</span>
                        <div class="tw-p-2">
                            <Slider @error="handleSliderError" @change="setInputRange(filter)" v-model="value" :min="min" :max="max" />
                        </div>
                        <div class="tw-flex tw-justify-between">
                            <span>{{ min }}</span>
                            <span>{{ max }}</span>
                        </div>
                    </div>
                </template>
            </AccordionCard>
        </div>
    </div>
</template>

<script>
import AccordionCard from "@/components/accordion";
import Input from "@/components/input";
import TreeNode from "@/components/tree-node";
import loader from "@/components/loader";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
    name: "filterComponent",
    data() {
        return {
            page: 1,
            limit: 6,
            customTitles: ["matchingIdentifiers", "topicClassification", "internetQueries", "saved", "monitoring", "sources"],
            staticFilters: ["saved", "monitoring", "sources"],
            value: [0, 40],
            minValue: 0,
            maxValue: 40,
            min: 0,
            max: 100,
            sliderError: "",
            savedData: [],
        };
    },
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
        handleSearchboxChange: Function,
        handleCheckboxChange: Function,
        fetchFilterData: Function,
        handleMoreData: Function,
        applyFilters: Function,
        resetFilters: Function,
        handleTopicSearch: Function,
        localSearch: Function,
    },
    components: {
        AccordionCard,
        Input,
        loader,
        TreeNode,
        Slider: VueSlider,
    },
    computed: {
        caseId() {
            return this.$route.params?.id;
        },
    },
    methods: {
        handleRadioSelection(name, data) {
            data.forEach((res) => {
                if (res.name == name) {
                    res.checked = true;
                } else {
                    res.checked = false;
                }
            });
        },
        showSelectAll(name) {
            const titles = ["matchingIdentifiers", "monitoring", "saved"];
            return !titles.includes(name);
        },
        handleSearch(filter) {
            if (filter.name == "internetQueries") {
                this.localSearch(filter, filter.searchedTerm);
            } else {
                this.handleSearchboxChange(filter, this.caseId);
            }
        },
        toggleTopics(event, filter) {
            let categories = JSON.stringify(filter.data);
            let string = JSON.stringify(event.data);
            const new_string = JSON.stringify(this.toggleRecursively(event));
            categories = categories.replace(string, new_string);
            filter.data = JSON.parse(categories);
            filter.data.forEach((res) => {
                this.handleSelectChild(res, event, filter);
            });

            // this.topicCategories = JSON.parse(JSON.stringify(data));
        },

        handleSelectChild(res, event, filter) {
            let count = 0;
            let childCount = 0;
            let {value, data} = event;
            if (res.children) {
                res.children.forEach((el) => {
                    if (el.children != null) {
                        this.handleSelectChild(el, event, filter);
                    } else {
                        if (event.data.length > 0) {
                            event.data.forEach((et) => {
                                if (!et.checked && et.category === el.category) {
                                    res.checked = false;
                                    this.handleParentElement(filter, res, event);
                                    return;
                                } else if (et.checked && et.category === el.category) {
                                    res.checked = true;
                                    this.handleParentElement(filter, res, event);
                                    return;
                                }
                            });
                        } else {
                            if (event.data.category === el.category && !event.data.checked) {
                                res.checked = false;
                                this.handleParentElement(filter, res, event);
                                return;
                            } else if (event.data.category === el.category && event.data.checked) {
                                res.children.forEach((el) => {
                                    if (!el.checked) {
                                        childCount++;
                                        console.log(event);
                                        return;
                                    }
                                });
                                if (childCount === 0) res.checked = true;
                                else res.checked = false;
                                this.handleParentElement(filter, res, event);
                            }
                        }
                    }
                });
            } else {
                if (event.data.category === res.category && !event.data.checked) {
                    res.checked = false;
                    this.handleParentElement(filter, res, event);
                    return;
                } else if (event.data.category === res.category && event.data.checked) {
                    res.checked = event.data.checked;
                    this.handleParentElement(filter, res, event);
                }
            }
            filter.data.forEach((res) => {
                if (!res.checked) {
                    count++;
                    return;
                }
            });
            if (count > 0) filter.selectAll = false;
            else filter.selectAll = true;
        },

        handleParentElement(filter, res) {
            filter.data.forEach((ch) => {
                if (ch.children) {
                    ch.children.forEach((el) => {
                        if (el.category === res.category) {
                            ch.checked = res.checked;
                            return;
                        }
                    });
                }
            });
        },

        toggleRecursively(event) {
            let {value, data} = event;
            if (Array.isArray(data)) {
                for (let item of data) {
                    item.checked = value;
                    if (item?.children && item?.children?.length) {
                        this.toggleRecursively({
                            value,
                            data: item.children,
                        });
                    }
                }
            }

            return data;
        },

        handleSelectAll(filter) {
            filter.data.forEach((res) => {
                if (res.children != null) {
                    this.selectChildren(res, filter);
                } else res.checked = filter.selectAll;
            });
        },
        selectChildren(res, filter) {
            res.children.forEach((el) => {
                console.log(res);
                res.checked = filter.selectAll;
                if (el.children != null) {
                    el.checked = filter.selectAll;
                    this.selectChildren(el, filter);
                } else el.checked = filter.selectAll;
            });
        },
        selectInput(filter) {
            for (let resp of filter.data) {
                if (resp.checked == false) {
                    filter.selectAll = false;
                    break;
                } else if (resp.checked == true) {
                    filter.selectAll = true;
                }
            }
        },
        setRange(filter) {
            this.sliderError = "";
            this.value = [this.minValue, this.maxValue];
            filter.data = [parseInt(this.minValue), parseInt(this.maxValue)];
        },
        setInputRange(filter) {
            this.minValue = this.value[0];
            this.maxValue = this.value[1];
            filter.data[0] = this.minValue;
            filter.data[1] = this.maxValue;
        },
        handleSliderError(type, message) {
            this.sliderError = message;
        },
    },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
