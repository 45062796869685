export default {
    methods: {
        getToolsData(tabName, toolName) {
            let dataRes = tabName === "tools" ? this.getAllToolsData : this.getAllMonitoringData;
            dataRes = dataRes?.find((n) => n["tool-name"] === toolName);
            if (dataRes) {
                const request_id = dataRes?.request_id;
                let match;
                if (tabName === "monitoring" || tabName === "monitoring-ro") match = this.toolSearchQueries?.find((el) => el._id === request_id && el.is_monitoring);
                else match = this.toolSearchQueries?.find((el) => el._id === request_id);
                if (match)
                    Object.entries(dataRes).forEach(([key, value]) => {
                        this[key] = value;
                    });
            }
            return dataRes;
        },

        setToolsData(tabName, toolName) {
            if (tabName === "tools") {
                this.SET_ALL_TOOLS_DATA({
                    ...this._data,
                    "tool-name": toolName,
                });
            } else {
                this.SET_ALL_MONITORING_DATA({
                    ...this._data,
                    "tool-name": toolName,
                });
            }
        },
    },
};
